import { Controller } from 'stimulus'
import QRCode from 'qrcode-svg'
import Scheduler from '../attendance/scheduler'

// This should only be called in case that socket connection does not work

// As we do not know when the OTP will change, we pull every 1.5 sec
// and wait until it changes. When it does, we will be very close to
// the refresh moment, thus we pull every 30sec

const timers = {
  initial: 1500,
  stable: 30000
}

export default class QRCodePullController extends Controller {
  constructor() {
    super()
    this.token = null
    this.QRCode = undefined
    this.timer = timers.initial
  }

  connect() {
    this.clearToken()
    this.fetchOTP()
    this.onDailyPageReload()
    this.setupEventListeners()
  }

  setupEventListeners = () => {
    document.addEventListener('visibilitychange', this.handleVisibilityChange)
    document.addEventListener('keydown', this.handleUserActivity)
    document.addEventListener('touchstart', this.handleUserActivity)
    document.addEventListener('touchmove', this.handleUserActivity)
  }

  handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      this.fetchOTP()
    }
  }

  handleUserActivity = () => {
    this.fetchOTP()
  }

  renderQR() {
    const qr_code = new QRCode({
      content: this.QRCode.toString(),
      join: true,
      container: 'svg-viewbox',
      padding: 2,
      width: 200,
      height: 200
    }).svg()

    const qr_container = document.getElementById('qr_code')
    qr_container.innerHTML = qr_code.trim()
  }

  fetchOTP() {
    const endpoint = this.getQRPullURL()
    const params = {
      method: 'GET',
      accept: 'application/json'
    }

    Scheduler.stop()

    fetch(endpoint, params)
      .then((response) => {
        if (response.ok) {
          return response.json()
        }

        Scheduler.plan(() => {
          this.fetchOTP()
        }, timers.initial)

        return Promise.reject(response)
      })
      .then((response) => this.onFetchSuccess(response))
      .catch((error) => this.onFetchError(error))
  }

  onFetchSuccess(response) {
    try {
      const { qr_code } = response

      // Set first time QR
      if (!this.QRCode) {
        this.QRCode = qr_code
        this.renderQR()
      }

      // Check if QR changed and asjut timer if needed
      if (qr_code !== this.QRCode) {
        if (this.timer === timers.initial) {
          this.timer = timers.stable
        }
        this.QRCode = qr_code
        this.renderQR()
      }

      Scheduler.plan(() => {
        this.fetchOTP()
      }, this.timer)
    } catch (e) {
      Sentry.captureException(e, { response })

      return Scheduler.plan(() => {
        this.fetchOTP()
      }, timers.initial)
    }
  }

  onFetchError(error) {
    console.error('Error:', error)
    // If some requests have done ok...
    if (this.timer === timers.stable) {
      this.pageReload()
      // If not let's try again
    } else {
      this.timer = timers.initial

      Scheduler.plan(() => {
        this.fetchOTP()
      }, this.timer)
    }
  }

  onDailyPageReload() {
    const DAY = 24 * 60 * 60 * 1000
    setTimeout(() => {
      this.pageReload()
    }, DAY)
  }

  pageReload() {
    window.location.replace(this.getURLWithToken())
  }

  getQRPullURL() {
    const { href } = window.location

    const splitted = href.split('/')
    // TODO: define this route somehwere with other routes
    const pathWithToken = 'entrance/qr_code'.concat('?token=', this.token)

    splitted.pop()
    splitted.push(pathWithToken)
    return splitted.join('/')
  }

  getURLWithToken() {
    return window.location.pathname.concat('?token=', this.token)
  }

  clearToken() {
    this.token = this.getParameterByName('token')
    window.history.replaceState({}, 'Factorial', window.location.pathname)
  }

  getParameterByName(name) {
    const url = new URL(window.location.href)
    return url.searchParams.get(name)
  }
}
