// This class is abstraction of setTimeout and clearTimeout
// clearTimeout is tricky, we have to verify the presence of past action
// before assign a new one, otherwise JS create a kind of stack on memory with
// all func's and will call them with no mercy.
//
// https://developer.mozilla.org/en-US/docs/Web/API/clearTimeout
class Scheduler {
  #nextActionId;

  plan(func, timeout) {
    if (typeof this.#nextActionId === 'number') {
      this.stop();
    }

    this.#nextActionId = setTimeout(func, timeout);
  }

  stop() {
    clearTimeout(this.#nextActionId);
    this.#nextActionId = undefined;
  }
}

const instance = new Scheduler();

export default instance;
